







import Vue from 'vue'

interface myList {
	value: string
	name: string
	id?: string
	disabled?: boolean
}

export default Vue.extend({
	props: {
		/** 字符数组 */
		fontList: {
			type: Array,
			default: () => {
				return [
					{
						disabled: false,
						name: '选项1',
						value: 1,
					},
				]
			},
		},
	},
	data() {
		return {
			message: '123',
			activeIndex: '1',
		}
	},
	computed: {
		arrfontList(): Array<myList> {
			return this.fontList as Array<myList>
		},
	},
	methods: {
		handleSelect(e) {
			let findItem = this.arrfontList.find((item) => item.value == e)
			if (findItem) {
				this.$emit('changeTab', findItem)
			} else {
				this.$emit('changeTab', e)
			}
		},
	},
	created() {},
})
