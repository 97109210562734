




























































import Menu from '@/components/menu/menu.vue'
import Vue from 'vue'
import { getHotelList } from '@/api/hotel'
import { getOrderList, getOrderListFailed, putOrderListFailed, putOrderList, cancleOrderList } from '@/api/ota'
import { export_json_to_excel } from '@/vendor/Export2Excel'
export default Vue.extend({
	components: {
		Menu,
	},
	data() {
		return {
			showInfo1: true,
			showInfo2: true,
			hotel_name: '',
			pms_hotel_id: '',
			total: 0,
			searchForm: {
				guest_name: '', // 客人姓名
				order_code: '', // 订单号
				stay_date: [] as Array<string>, //入住和离店日期查询
				pms_hotel_code: '', // PMS酒店编码
				pms_hotel_id: '', // PMS酒店id
			},
			orderStauts: '1',
			table_data: [],
			table_state: {
				loading: false,
			},
			topMenuList: [
				{
					name: '携程订单',
					value: '1',
					disabled: false,
				},
				{
					name: '美团订单',
					value: '2',
					disabled: true,
				},
				{
					name: '飞猪订单',
					value: '3',
					disabled: true,
				},
				{
					name: '驴妈妈订单',
					value: '4',
					disabled: true,
				},
				{
					name: '马蜂窝订单',
					value: '5',
					disabled: true,
				},
				{
					name: '途牛订单',
					value: '6',
					disabled: true,
				},
			],
			orderStatusList: [
				{
					name: '未处理订单',
					value: '1',
					disabled: false,
				},
				{
					name: '失败订单',
					value: '2',
					disabled: false,
				},
				{
					name: '成功订单',
					value: '3',
					disabled: false,
				},
			],
			hotels: [] as any,
			limit: 10,
			page: 1,
		}
	},
	created() {
		getHotelList().then((res) => {
			if (res.code == 0) {
				this.hotels = res.data.rows
				this.searchForm.pms_hotel_id = this.hotels[0].id
				this.searchForm.pms_hotel_code = this.hotels[0].miccode
				this.hotel_name = this.hotels[0].name
				this.pms_hotel_id = this.hotels[0].id
				this.getXcpmsOrder()
			}
		})
	},
	methods: {
		changeSearchInfo() {
			this.changeStatusTabTop({ value: this.orderStauts })
		},
		changehotels(e) {
			let find = this.hotels.find((item) => {
				return item.id == e
			})
			if (find) {
				this.hotel_name = find.name
				this.searchForm.pms_hotel_code = find.miccode
			}
			this.changeStatusTabTop({ value: this.orderStauts })
		},
		searchFormMethods() {
			this.page = 1
			this.changeStatusTab({ value: this.orderStauts })
		},
		changeLimit(data) {
			this.page = 1
			this.limit = data
			// 1未处理 2失败 3成功
			if (this.orderStauts == '1') {
				this.getXcpmsOrder()
			} else if (this.orderStauts == '2') {
				this.getOrderfailed()
			} else if (this.orderStauts == '3') {
				this.getXcpmsOrderSuccess()
			}
		},
		changePages(data) {
			this.page = data
			this.changeStatusTab({ value: this.orderStauts })
		},
		changeDate() {
			if (this.searchForm.stay_date == null) {
				this.searchForm.stay_date = []
			}
			this.changeStatusTabTop({ value: this.orderStauts })
		},
		// 获取当前酒店的未处理订单
		getXcpmsOrder() {
			let my_stay_date = this.searchForm.stay_date == null ? [] : this.searchForm.stay_date.join('-')
			this.table_state.loading = true
			getOrderList({
				...this.searchForm,
				stay_date: my_stay_date,
				page: this.page,
				limit: this.limit,
				manual_result: 1,
			})
				.then((res) => {
					if (res.code == 0) {
						this.total = res.count
						let data: any = res.data.map((item) => {
							// let splitInfo = item.request_result.split(':')
							// const regex = /'([^']*)'/
							// const matches = splitInfo[3].match(regex)
							return {
								...item,
								// request_result: matches[1],
							}
						})
						this.table_data = data
					}
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},
		getXcpmsOrderSuccess() {
			let my_stay_date = this.searchForm.stay_date == null ? [] : this.searchForm.stay_date.join('-')
			this.table_state.loading = true
			getOrderList({
				...this.searchForm,
				stay_date: my_stay_date,
				page: this.page,
				limit: this.limit,
				request_result: 1,
			})
				.then((res) => {
					if (res.code == 0) {
						this.total = res.count
						let data: any = res.data.map((item) => {
							// let splitInfo = item.request_result.split(':')
							// const regex = /'([^']*)'/
							// const matches = splitInfo[3].match(regex)
							return {
								...item,
								// request_result: matches[1],
							}
						})
						this.table_data = data
					}
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},
		getOrderfailed() {
			this.table_state.loading = true
			let my_stay_date = this.searchForm.stay_date == null ? [] : this.searchForm.stay_date.join('-')
			getOrderListFailed({
				...this.searchForm,
				stay_date: my_stay_date,
				page: this.page,
				limit: this.limit,
			})
				.then((res) => {
					if (res.code == 0) {
						this.total = res.count
						let data: any = res.data.map((item) => {
							// let splitInfo = item.failed_reason.split(':')
							// const regex = /'([^']*)'/
							// const matches = splitInfo[3].match(regex))
							return {
								...item,
								// failed_reason: matches[1],
							}
						})
						this.table_data = data
					}
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},
		changeStatusTabTop(e) {
			this.page = 1
			this.orderStauts = e.value
			this.changeStatusTab({ value: this.orderStauts })
		},
		changeStatusTab(e) {
			this.orderStauts = e.value
			// 1未处理 2失败 3成功
			if (this.orderStauts == '1') {
				this.getXcpmsOrder()
			} else if (this.orderStauts == '2') {
				this.getOrderfailed()
			} else if (this.orderStauts == '3') {
				this.getXcpmsOrderSuccess()
			}
		},
		cancelOrder(row) {
			this.$confirm(
				`<span style="font-size: 24px; text-align: center;"><i class="el-icon-warning" style="color: #E6A23C;"></i></span ><span style="line-height: 15px;">确定对订单号为${row.order_code}进行取消操作吗？</span>`,
				{
					distinguishCancelAndClose: true,
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}
			)
				.then(() => {
					cancleOrderList({
						order_code: row.order_code,
						pms_hotel_code: this.searchForm.pms_hotel_code,
					}).then((res) => {
						if (res.code == 0) {
							this.$message({
								type: 'success',
								message: '操作成功',
							})
							let findItem: any = this.table_data.findIndex((item: any) => {
								return item.id == row.id
							})
							if (findItem !== -1) {
								this.$set(this.table_data[findItem], 'manual_result', 4)
							}
							// this.changeStatusTab({ value: this.orderStauts })
						}
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作',
					})
				})
		},
		overEnter(row) {
			this.$confirm(`确定此条数据完成录入？`, {
				distinguishCancelAndClose: true,
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			})
				.then(() => {
					putOrderList({
						id: row.id,
					}).then((res) => {
						if (res.code == 0) {
							this.$message({
								type: 'info',
								message: '操作成功',
							})
							this.changeStatusTab({ value: this.orderStauts })
						}
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作',
					})
				})
		},
		tryNext(row) {
			//<i class="el-icon-warning" style="color:red; font-size: 24px; "></i>
			this.$confirm(
				`<span style="font-size: 24px; text-align: center;"><i class="el-icon-warning" style="color: #E6A23C;"></i></span>确认错误已修正后，重新尝试，RPA将再次向PMS推送数据？`,
				{
					distinguishCancelAndClose: true,
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}
			)
				.then(() => {
					putOrderListFailed({
						id: row.id,
					}).then((res) => {
						if (res.code == 0) {
							this.$message({
								type: 'info',
								message: '操作成功',
							})
							this.changeStatusTab({ value: this.orderStauts })
						}
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作',
					})
				})
		},
		// 切换 订单类型
		changeChannelTab(data) {
			// console.log(data)
		},
		// 导出
		async que() {
			let Listresult
			this.orderStauts
			let my_stay_date = this.searchForm.stay_date == null ? [] : this.searchForm.stay_date.join('-')
			let queSearchForm = {
				...this.searchForm,
				stay_date: my_stay_date,
				page: this.page,
				limit: this.limit,
				export: '1',
			}
			let tHeader: Array<string> = ['序号', '订单号', '酒店名称', '客人姓名', '房型名称', '住宿时间'] //将对应的属性名转换成中文
			let tHeaderValue: Array<string> = ['id', 'order_code', 'hotel_name', 'guest_name', 'sale_room_type', 'stay_date'] //table表格中对应的属性名
			let tTitle = ''
			// 1未处理 2失败 3成功
			if (this.orderStauts == '1') {
				Listresult = await getOrderList({
					...queSearchForm,
					manual_result: 1,
				})
				tHeader = [...tHeader, '订单类型']
				tHeaderValue = [...tHeaderValue, 'request_result']
				tTitle = this.hotel_name + '未处理订单'
			} else if (this.orderStauts == '2') {
				Listresult = await getOrderListFailed({
					...queSearchForm,
				})
				tHeader = [...tHeader, '失败原因']
				tHeaderValue = [...tHeaderValue, 'failed_reason']
				tTitle = this.hotel_name + '失败订单'
			} else if (this.orderStauts == '3') {
				Listresult = await getOrderList({
					...queSearchForm,
					request_result: 1,
				})
				tHeader = [...tHeader, '间数', '价格']
				tHeaderValue = [...tHeaderValue, 'room_count', 'room_price']
				tTitle = this.hotel_name + '成功订单'
			}
			let dataresult = Listresult.data
			//兼容ie10哦！导出功能
			require.ensure([], () => {
				const data = this.formatJson(tHeaderValue, dataresult)
				export_json_to_excel(tHeader, data, tTitle)
			})
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) => filterVal.map((j) => v[j]))
		},
	},
})
